import cx from "classnames";

function Container({
  children,
  className,
  px = 4,
  id,
  ...props
}: {
  children: React.ReactNode;
  className?: string;
  px?: number;
  id?: string;
}) {
  return (
    <div
      className={cx(`mx-auto w-full max-w-4xl px-${px}`, {
        [`${className}`]: className,
      })}
      id={id}
      {...props}
    >
      {children}
    </div>
  );
}

export default Container;
